import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import {
  EEventContextAction,
  EEventType,
  type ICountry,
  type ICustomerCurrencyType,
  type IDelivery,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getHead } from '../../head';
import { type ICountryState } from '../reducers/country/types';
import { type ICurrencyState } from '../reducers/currency/types';
import { eventSend } from '../reducers/event/reducer';
import { setActiveCategory } from '../reducers/products/reducer';
import { type IUserState } from '../reducers/user/types';

function DeliveryContainer({
  Currency,
  Country,
  User,
  dispatch,
}: {
  Currency: ICurrencyState;
  Country: ICountryState;
  User: IUserState;
  dispatch: Dispatch;
}): JSX.Element {
  useEffect(() => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_DELIVERY,
        action: EEventContextAction.ENTER,
      }),
    );
    dispatch(
      setActiveCategory({
        category: {
          uuid: '',
        },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_DELIVERY,
          action: EEventContextAction.LEAVE,
        }),
      );
    };
  }, [dispatch]);

  const { t } = useTranslation();

  useEffect((): void => {
    const { title } = getHead(t, t('navigation.title.delivery'));
    document.title = title;
  }, [t]);

  const rows = (): JSX.Element[] => {
    return Country.countries.map((country: ICountry): JSX.Element => {
      const currency: ICustomerCurrencyType | undefined =
        Currency.currencies.find(
          (c: ICustomerCurrencyType): boolean =>
            c.isoCode === User.currencyType?.isoCode,
        );

      const deliveries: IDelivery[] = Country.deliveries.filter(
        (d: IDelivery): boolean =>
          d.countryType.isoCode === country.isoCode &&
          d.currencyType.isoCode === User.currencyType?.isoCode,
      );

      const d1: IDelivery | undefined = deliveries.find(
        (d: IDelivery): boolean => d.weight === 1,
      );
      const d2: IDelivery | undefined = deliveries.find(
        (d: IDelivery): boolean => d.weight === 2,
      );
      const d5: IDelivery | undefined = deliveries.find(
        (d: IDelivery): boolean => d.weight === 5,
      );
      const d10: IDelivery | undefined = deliveries.find(
        (d: IDelivery): boolean => d.weight === 10,
      );

      return (
        <tr key={country.isoCode}>
          <th>
            {`${country.name} (${country.isoCode})`}
            <br />
            <small>{country.delivery.description}</small>
          </th>
          <th>{country.delivery.time}</th>
          <th>
            {d1 ? formatCurrency(d1.price, currency) : ''}
            <br />
            <small>
              {d1 && d1.priceWithoutTax
                ? formatCurrency(d1.priceWithoutTax, currency)
                : ''}
            </small>
          </th>
          <th>
            {d2 ? formatCurrency(d2.price, currency) : ''}
            <br />
            <small>
              {d2 && d2.priceWithoutTax
                ? formatCurrency(d2.priceWithoutTax, currency)
                : ''}
            </small>
          </th>
          <th>
            {d5 ? formatCurrency(d5.price, currency) : ''}
            <br />
            <small>
              {d5 && d5.priceWithoutTax
                ? formatCurrency(d5.priceWithoutTax, currency)
                : ''}
            </small>
          </th>
          <th>
            {d10 ? formatCurrency(d10.price, currency) : ''}
            <br />
            <small>
              {d10 && d10.priceWithoutTax
                ? formatCurrency(d10.priceWithoutTax, currency)
                : ''}
            </small>
          </th>
        </tr>
      );
    });
  };

  return (
    <>
      <h1>{t('delivery.title')}</h1>

      <p>{t('delivery.feeCalculation')}</p>

      <h2>{t('delivery.feeForCountry')}</h2>

      <table className="deliveryTable">
        <thead>
          <tr>
            <th>{t('delivery.nameWithCode')}</th>
            <th>{t('delivery.time')}</th>
            <th>
              1 kg
              <br />
              <small>{t('delivery.price')}</small>
              <br />
              <small>{t('delivery.priceWithoutVAT')}</small>
            </th>
            <th>
              2 kg
              <br />
              <small>{t('delivery.price')}</small>
              <br />
              <small>{t('delivery.priceWithoutVAT')}</small>
            </th>
            <th>
              5 kg
              <br />
              <small>{t('delivery.price')}</small>
              <br />
              <small>{t('delivery.priceWithoutVAT')}</small>
            </th>
            <th>
              10 kg
              <br />
              <small>{t('delivery.price')}</small>
              <br />
              <small>{t('delivery.priceWithoutVAT')}</small>
            </th>
          </tr>
        </thead>
        <tbody>{rows()}</tbody>
      </table>
    </>
  );
}

const mapStateToProps = ({ Currency, Country, User }) => ({
  Currency,
  Country,
  User,
});

export default connect(mapStateToProps)(DeliveryContainer);
