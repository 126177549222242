import { formatCurrency } from '@mahawi/eshop-common/dist/src/format-currency';
import {
  EEventContextAction,
  EEventType,
  type IBasketProduct,
  type ICurrencyPrice,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type Dispatch } from '@reduxjs/toolkit';
import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getHead } from '../../head';
import LinearProgress from '../components/linear-progress';
import Photos from '../components/photos';
import Breadcrumbs from '../components/product/breadcrumbs';
import Description from '../components/product/description';
import LanguageMutationLinks from '../components/product/language-mutation-links';
import Properties from '../components/product/properties';
import Related from '../components/product/related';
import { addProduct } from '../reducers/basket/reducer';
import { type IBasketState } from '../reducers/basket/types';
import { type IConfigState } from '../reducers/config/types';
import { eventSend } from '../reducers/event/reducer';
import { type ILanguageState } from '../reducers/language/types';
import { type IProductState } from '../reducers/product/types';
import { setActiveCategory } from '../reducers/products/reducer';
import { type IUserState } from '../reducers/user/types';
import { type ITagManager } from '../types';

function ProductContainer({
  User,
  Product,
  Config,
  Basket,
  dispatch,
  Language,
  TagManager,
}: {
  User: IUserState;
  Product: IProductState;
  Config: IConfigState;
  Basket: IBasketState;
  dispatch: Dispatch;
  Language: ILanguageState;
  TagManager: ITagManager | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const [showAddedToCartMessage, setShowAddedToCartMessage] =
    React.useState(false);

  useEffect((): (() => void) => {
    dispatch(
      eventSend({
        type: EEventType.PAGE_PRODUCT,
        action: EEventContextAction.ENTER,
        context: { product: { uuid: Product.product?.uuid } },
      }),
    );

    return (): void => {
      dispatch(
        eventSend({
          type: EEventType.PAGE_PRODUCT,
          action: EEventContextAction.LEAVE,
          context: { product: { uuid: Product.product?.uuid } },
        }),
      );
    };
  }, [dispatch, Product.product?.uuid]);

  useEffect((): void => {
    if (!Product.product) {
      return;
    }

    const [category] = Product.product.categories;

    if (!category) {
      return;
    }

    dispatch(
      setActiveCategory({
        category: {
          uuid: category.uuid,
        },
      }),
    );
  }, [Product.product, Product.product?.categories, dispatch]);

  useEffect((): void => {
    if (showAddedToCartMessage) {
      setTimeout((): void => {
        setShowAddedToCartMessage(false);
      }, 5000);
    }
  }, [showAddedToCartMessage]);

  useEffect((): void => {
    if (!TagManager) {
      return;
    }

    const productNameEn: ITranslation | undefined = Product.product?.names.find(
      (n: ITranslation): boolean => n.code === 'EN',
    );
    const productPriceEur: ICurrencyPrice | undefined =
      Product.product?.prices.find(
        (c: ICurrencyPrice): boolean => c.isoCode === 'EUR',
      );

    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: Product.product?.uuid,
              item_name: productNameEn?.value || '---',
              price: productPriceEur?.price || 0,
              currency: 'EUR',
            },
          ],
        },
      },
    });
  }, [
    Product.product?.names,
    Product.product?.prices,
    TagManager,
    Product.product?.uuid,
  ]);

  if (Product.isLoading) {
    return <LinearProgress />;
  }

  const {
    languageType: { code: languageCode },
  } = Language;

  let name: ITranslation | undefined = Product.product?.names.find(
    (n: ITranslation): boolean => n.code === languageCode,
  );

  if (!name) {
    name = Product.product?.names.find(
      (n: ITranslation): boolean => n.code === 'EN',
    );
  }

  if (typeof document !== 'undefined' && name) {
    const { title } = getHead(t, name ? name.value : '');
    document.title = title;
  }

  const inBasket: IBasketProduct | undefined = Basket.products.find(
    (p: IBasketProduct): boolean => p.uuid === Product.product?.uuid,
  );
  const currencyPrice: ICurrencyPrice | undefined =
    Product.product?.prices.find(
      (cp: ICurrencyPrice): boolean =>
        cp.isoCode === (User.currencyType?.isoCode || 'EUR'),
    );

  let outOfStockMessage: string = '';

  if (Product.product?.nextDeliveryDate !== '') {
    outOfStockMessage = `${t('product.outOfStockDeliveryDate', {
      nextDeliveryDate: Product.product?.nextDeliveryDate,
    })}`;
  }

  const stockMessage = `${t('product.onStock', {
    count: Product.product?.stock,
  })}`;

  const headerTwoElements: string[] = [];

  if (Product.product?.brand && Product.product?.brand.name) {
    headerTwoElements.push(Product.product?.brand.name);
  }

  if (Product.product?.mpn) {
    headerTwoElements.push(Product.product?.mpn);
  }

  if (Product.product?.gtin) {
    headerTwoElements.push(Product.product?.gtin);
  }

  const headerTwo: string = headerTwoElements.join(' | ');

  if (!Product.product) {
    return <div>...</div>;
  }

  return (
    <>
      <div className="product container">
        <Breadcrumbs />
        <h1>{name ? name.value : ''}</h1>
        <h2>{headerTwo}</h2>
      </div>

      <Photos
        photos={Product.product.photos}
        cdn={Config.cdn}
        name={name ? name.value : ''}
        dispatch={dispatch}
      />

      <div className="container--3 container--t">
        {Product.product.stock > 0 && <p>{stockMessage}</p>}

        {Product.product.preOrderDays > 0 && (
          <p className="warning">
            {t('product.preOrder', {
              preOrderDays: Product.product.preOrderDays,
            })}
          </p>
        )}

        <div className="divider" />

        {!outOfStockMessage &&
          Product.product.stock === 0 &&
          `${t('product.outOfStock')}`}

        {currencyPrice && (
          <>
            <p>
              <strong>
                {t('product.price', {
                  price: formatCurrency(currencyPrice.price, User.currencyType),
                })}
              </strong>
            </p>
            <p>
              <small>
                {t('product.priceWithoutTax', {
                  price: formatCurrency(
                    currencyPrice.priceWithoutTax,
                    User.currencyType,
                  ),
                })}
              </small>
            </p>
          </>
        )}

        <p>
          {t('product.weight', { weight: Product.product.dimensions.weight })}
        </p>

        <p>
          {t('product.actualInCart', { count: inBasket ? inBasket.count : 0 })}
        </p>

        {Product.product && Product.product.stock > 0 && (
          <Formik
            initialValues={{
              count: 1,
            }}
            onSubmit={({ count }): void => {
              if (!Product.product) {
                return;
              }

              let properCount: number = count;

              if (count >= Product.product.stock) {
                properCount = Product.product.stock;
              }

              dispatch(
                addProduct({
                  product: Product.product,
                  count: properCount,
                  discount: {
                    code: Basket.discount.code,
                  },
                }),
              );

              dispatch(
                eventSend({
                  type: EEventType.PRODUCT_ADD_TO_BASKET,
                  action: EEventContextAction.CHANGE,
                  context: {
                    product: { uuid: Product.product.uuid },
                    count: properCount,
                  },
                }),
              );

              setShowAddedToCartMessage(true);

              if (TagManager) {
                const productNameEn: ITranslation | undefined =
                  Product.product.names.find(
                    (n: ITranslation): boolean => n.code === 'EN',
                  );
                const productPriceEur: ICurrencyPrice | undefined =
                  Product.product.prices.find(
                    (c: ICurrencyPrice): boolean => c.isoCode === 'EUR',
                  );

                TagManager.dataLayer({
                  dataLayer: {
                    event: 'add_to_cart',
                    ecommerce: {
                      items: [
                        {
                          item_id: Product.product.uuid,
                          item_name: productNameEn?.value || '---',
                          price: productPriceEur?.price || 0,
                          quantity: properCount,
                          currency: 'EUR',
                        },
                      ],
                    },
                  },
                });
              }
            }}
          >
            {({ submitForm }): JSX.Element => (
              <Form>
                <label htmlFor="count">{t('product.count')}</label>
                <Field
                  id="count"
                  name="count"
                  type="number"
                  disabled={Basket.inProgress}
                  className="product-count"
                />
                <button
                  type="submit"
                  disabled={Basket.inProgress}
                  onClick={submitForm}
                  className="product-button"
                >
                  {t('product.addToCart')}
                </button>
              </Form>
            )}
          </Formik>
        )}

        <div
          className={`product-added-to-basket ${
            showAddedToCartMessage ? 'show' : 'hide'
          }`}
        >
          {t('product.productWasAddedToYourCart')}
        </div>
      </div>

      <Description />

      <Properties />

      <Related />

      <LanguageMutationLinks />
    </>
  );
}

const mapStateToProps = ({ Basket, Config, Language, Product, User }) => ({
  Basket,
  Config,
  Language,
  Product,
  User,
});

export default connect(mapStateToProps)(ProductContainer);
