import { createSlice } from '@reduxjs/toolkit';

import {
  CreateOrder,
  type CreateOrderResponse,
  type IOrderState,
} from './types';

export const initialState: IOrderState = {
  uuid: '',
  currencyPrice: {
    price: 0,
    priceWithoutTax: 0,
    isoCode: 'EUR',
  },
  redirectLink: '',
  isLoading: false,
  orderStatusCode: '',
  products: [],
  packagesCount: 0,
  orderDelivery: {
    fee: 0,
    feeWithoutTax: 0,
    packagesCount: 0,
  },
  discount: {
    discountType: {
      code: '',
    },
    code: '',
    value: 0,
  },
  companyHasPricesWithoutTax: false,
};

const orderSlice = createSlice({
  name: 'Order',
  initialState,
  reducers: {
    reset: (state): void => {
      state = initialState;
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    create: (_state, _action: CreateOrder): void => {},
    createResponse: (state, action: CreateOrderResponse): void => {
      state.uuid = action.payload.uuid;
      state.redirectLink = action.payload.redirectLink;
    },
  },
});

export const { reset, create, createResponse } = orderSlice.actions;

export default orderSlice.reducer;
